import React from "react"
import { MarketingDashSVG } from "../MarketingDashSVG"
import { Body, H4 } from "../Typography"
import {
    IconAligner,
    MarketingThreeBlockContainer,
    MarketingTwoBlockContainer,
    MarketingIconBlockWrapper,
    MarketingBlockWrapper
} from "./MarketingIconsBLock.styles"

type MarketingIconsBlockProps = {
    icons: JSX.Element[],
    useDash?: boolean
}

export const MarketingIconsBlock = ({ icons, useDash = true }: MarketingIconsBlockProps) => {
    const Container = icons.length === 2 ? MarketingTwoBlockContainer : MarketingThreeBlockContainer;

    return <MarketingBlockWrapper>
        {useDash && <MarketingDashSVG />}
        <Container>
            {icons.map((icon, index) => <React.Fragment key={index}>
                {icon}
            </React.Fragment>)}
        </Container>
    </MarketingBlockWrapper>
}

export type MarketingIconsBlock = {
    title: JSX.Element,
    icon: JSX.Element,
    text: JSX.Element,
    minHeight?: number
}

export const MarketingIconBlock = ({ title, icon, text, minHeight }: MarketingIconsBlock) => {
    return <MarketingIconBlockWrapper>
        <IconAligner $minHeight={minHeight}>
            {icon}
        </IconAligner>
        <H4>{title}</H4>
        <Body>{text}</Body>
    </MarketingIconBlockWrapper>
}