import GoogleTagManagerTracker from "../tracking/GoogleTagManager";
import IntercomTracker from "../tracking/Intercom";
import FacebookPixelTracker from "../tracking/FacebookPixel";
import TwitterPixelTracker from "../tracking/TwitterPixel";

export const useMarketingPagesTracking = () => {
    new GoogleTagManagerTracker(null);
    new IntercomTracker(undefined, false);
    const facebookPixelTracker = new FacebookPixelTracker(null);
    const twitterPixelTracker = new TwitterPixelTracker(null);

    facebookPixelTracker && facebookPixelTracker.trackPageView();
    twitterPixelTracker && twitterPixelTracker.trackPageView();
};