import styled from "styled-components";
import { media } from "../../theme/theme";
import { MarketingSpacing } from "../ReusableStyledComponents/MarketingSpacing";
import { H2 } from "../Typography";
import { HomepageVariantCustomH2 } from "../../pagelayouts/homepage/HomepageVariantThree.styles";

const MDBR = 930;
const SMBR = 612;

export const AllBenefitsWrapper = styled.div<{ $useBlockVariant?: boolean }>`
    width: 100%;
    max-width: ${({ $useBlockVariant }) => $useBlockVariant ? "935px" : undefined};
    background: ${({ theme }) => theme ? theme.marketing.colors.blackPearl30 : null};
    padding: ${({ $useBlockVariant }) => $useBlockVariant ? "60px 0 45px" : "45px 0"};
    margin: 0 auto;

    ${media.tablet} {
        padding: ${({ $useBlockVariant }) => $useBlockVariant ? "90px 0 40px" : "60px 0"};
    }
`;

export const AllBenefitsContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;

    ${H2} {
        text-align: center;
        margin-bottom: 50px;
    }

    ${HomepageVariantCustomH2} {
        text-align: center;
        margin-bottom: 30px;
    }
`;

export const CardContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    ${MarketingSpacing}
    
    a {
        text-decoration: none;
    }

    > div {
        padding: 16px;
        background: #FFFFFF;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.13);
        border-radius: 4px;
        margin-bottom: 20px;
        width: 100%;
        position: relative;
        
        ${media.custom(SMBR)} {
            width: calc(50% - 10px);
        }

        ${media.custom(MDBR)} {
            width: calc(33.3% - 14px);
        }

        &:not(:nth-child(2n)) {
            ${media.custom(SMBR)} {
                margin-right: 20px;
            }
            
            ${media.custom(MDBR)} {
                margin-right: 0;
            }
        }

        &:not(:nth-child(3n)) {

            ${media.custom(MDBR)} {
                margin-right: 20px;
            }
        }

        &:nth-child(7) {
            ${media.custom(SMBR)} {
                margin-right: 0;
            }

            ${media.custom(MDBR)} {
                margin-right: 0;
            }
        }
    }
`;

export const SuccessIconSVG = styled.svg`
    position: absolute;
    top: 8px;
    right: 8px;
`;

export const MarketingAllBenefitsBlockVariantTitle = styled(H2)`
    font-size: 25px;
    line-height: 31px;
    ${MarketingSpacing};

    ${media.tablet} {
        font-size: 32px;
        line-height: 40px;
    }
`;