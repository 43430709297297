import React from "react";
import { IconVariant } from "../../types/icons";
import { MarketingUseCasePanel } from "../MarketingUseCasePanel";
import { SuccessIconSVG } from "./MarketingAllBenefits.styles";

type AllBenefitsPanelProps = {
    currRoute?: string,
    route: string,
    primaryText: string,
    secondaryText: string,
    iconPath: string,
}

export const AllBenefitsPanel = ({ currRoute, route, primaryText, secondaryText, iconPath }: AllBenefitsPanelProps) => {
    const isActive = !!currRoute?.length && currRoute === route;

    return <div>
        <MarketingUseCasePanel
            icon={<img src={`/authory_static_files/images/icons/${iconPath}_m_${isActive ? IconVariant.Disabled : IconVariant.Active}.svg`} />}
            primaryText={primaryText}
            secondaryText={secondaryText}
            href={route}
            disabled={isActive}
        />
        {isActive && <SuccessIconSVG width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10.2821" cy="10.2821" r="10.2821" fill="#17D4BE" />
            <path d="M5.99792 10.1831L8.16868 13.1446C8.9842 14.2572 10.6547 14.2296 11.433 13.0907L16.28 5.99805" stroke="white" />
        </SuccessIconSVG>}
    </div>
}
