import styled from "styled-components";
import { media } from "../../theme/theme";
import { MarketingImageContainer, MarketingImageMobile } from "../ReusableStyledComponents/MarketingImageMobile";
import { MarketingSpacing } from "../ReusableStyledComponents/MarketingSpacing";
import { Body, H2 } from "../Typography";

const DEFAULT_SNAP_BR = 1100;

export const TextSVGWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
`;

export const DesktopImg = styled.div<{ desktopImgWidth: number, desktopImgHeight: number, snapBr?: number }>`
    display: none;
    
    ${media.desktop} {
        display: block;
    }

    ${({ snapBr }) => media.custom(snapBr ? snapBr : DEFAULT_SNAP_BR)} {
        display: block;
        overflow: hidden;
        height: 0;
        padding-top: ${({ desktopImgWidth, desktopImgHeight }) => desktopImgWidth && desktopImgHeight ? `calc(${desktopImgHeight} / ${desktopImgWidth} * 100%);` : null};  
    }

    img {
        max-width: 90%;

        ${({ snapBr }) => media.custom(snapBr ? snapBr : DEFAULT_SNAP_BR)} {
            max-width:  ${({ desktopImgWidth }) => desktopImgWidth ? `calc(0.9 * ${desktopImgWidth}px)` : null};  
        }
    }
`;

export const MobileImg = styled.div<{ maxWidth?: number }>`
    ${MarketingImageContainer}
    
    ${media.desktop} {
        display: none;
    }

    ${MarketingImageMobile}
`;

export const TextWrapper = styled.div<{ mobileCentered: boolean, homepageVariant?: boolean }>`
    max-width: ${({ homepageVariant }) => homepageVariant ? "620px" : "520px"};
    width: 100%;
    margin: 0 auto 30px;
    text-align: ${({ mobileCentered }) => mobileCentered ? "center" : null};
    ${MarketingSpacing}

    ${media.desktop} {
        text-align: initial;
        width: 50%;
        margin: initial;
    }

    ${H2} {
        margin-top: 5px;
        margin-bottom: 24px;

        ${media.desktop} {
            margin-bottom: ${({ homepageVariant }) => homepageVariant ? "22px" : "32px"};
        }
    }

    ${Body} {
        color: ${({ theme, color }) => color ? color : `${theme.marketing.colors.blackPearl80}`};
    }

    button {
        margin-top: 32px;

        ${media.phone} {
            margin-top: 64px;
        }
    }
`;

export const ImageContent = styled.div<{ snapBr?: number }>`
    width: 100%;
    margin: 0 auto;
    position: relative;

    ${media.desktop} {
        width: 50%;
        margin: initial;
    }

    ${DesktopImg} {
        img {
            display: block;
            margin: 0 auto;

            ${({ snapBr }) => media.custom(snapBr ? snapBr : DEFAULT_SNAP_BR)} {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
`;

export const TextSVGContainer = styled.div<{ svgPosition: "left" | "right", homepageVariant?: boolean }>`
    display: flex;
    justify-content: ${({ homepageVariant }) => homepageVariant ? undefined : "space-between"};
    align-items: center;
    flex-wrap: wrap;

    > div {
        &:nth-child(2) {
            ${media.desktop} {
                order: ${({ svgPosition }) => svgPosition === "left" ? "-1" : null};
            }
        }
    }
`;