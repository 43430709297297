import styled from "styled-components";
import { media } from "../../theme/theme";

export const SVGGeneric = styled.img`
    width: 109px;
    height: auto;

    ${media.phone} {
        width: 139px;
    }
`;