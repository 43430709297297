import styled from "styled-components";
import { MarketingSpacing } from "../../components/ReusableStyledComponents/MarketingSpacing";
import { H2, H4 } from "../../components/Typography";
import { media } from "../../theme/theme";

export const HomePageVariantCentered = styled.div`
    display: flex;
    justify-content: center;
    align-items:  center;
    flex-direction: column;
    ${MarketingSpacing}
`;

export const HomepageVariantCustomH4 = styled(H4)`
    color: ${({ theme }) => theme.v3.colors.blue};
    font-size: 22px;
    line-height: 30px;

    ${media.tablet} {
        font-size: 28px;
        line-height: 44px;
    }
`;

export const HomepageVariantCustomH2 = styled(H2)`
    font-size: 25px;
    line-height: 31px;

    ${media.tablet} {
        font-size: 38px;
        line-height: 47px;
    }
`;

export const HomepageBlockBackgroundWrapper = styled.div`
    > div {
        // border: 1px solid red;
        padding: 50px 0;

        ${media.tablet} {
            padding: 80px 0;
        }

        &:nth-child(even) {
            background: ${({ theme }) => theme ? theme.marketing.colors.blackPearl30 : null};
        }
    }
`;

export const HomepageAuthoryTeamImage = styled.img`
    width: 100%;
    margin: 0 auto;
    display: block;
    background: white;
    max-width: calc(100vw - 32px);
    border-radius: 12px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
    
    ${media.tablet} {
        max-width: 450px;
        position: absolute;
        top: 65%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`