import styled from "styled-components";
import { media } from "../../theme/theme";
import { MarketingSpacing } from "../ReusableStyledComponents/MarketingSpacing";
import { Body, H4 } from "../Typography";

const BLOCK_SPACING = 34;

export const MarketingBlockWrapper = styled.div`
    max-width: 1152px;
    margin: 0 auto;
    width: 100%;
    ${MarketingSpacing}
`;

export const MarketingTwoBlockContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;

    > div {
        width: 100%;

        &:not(:last-child) {
            margin-bottom:  74px;
        }

        ${media.phone} {
            width: 50%;
        }

        &:nth-child(1) {
            ${media.phone} {
                padding-right: ${BLOCK_SPACING}px;
            }
        }

        &:nth-child(2) {
            ${media.phone} {
                padding-left: ${BLOCK_SPACING}px;

                ${H4} {
                    &:after{
                        display: none;
                    }
                }
            }
        }
    }
`;

export const MarketingThreeBlockContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;

    > div {
        width: 100%;

        &:not(:last-child) {
            margin-bottom:  74px;
        }

        ${media.phone} {
            width: 50%;
        }

        ${media.desktop} {
            width: 33.3%;
        }

        &:nth-child(1) {
            ${media.phone} {
                padding-right: ${BLOCK_SPACING}px;
            }
        }

        &:nth-child(2) {
            ${media.phone} {
                padding-left: ${BLOCK_SPACING}px;

                ${H4} {
                    &:after{
                        display: none;
                    }
                }
            }

            ${media.desktop} {
                padding-right: ${BLOCK_SPACING}px;

                ${H4} {
                    &:after{
                        display: block;
                    }
                }
            }
        }

        &:nth-child(3) {

            ${media.tablet} {
                padding-left: ${BLOCK_SPACING}px;
            }

            ${H4} {
                &:after{
                    display: none;
                }
            }
        }
    }
`;

export const MarketingIconBlockWrapper = styled.div`
    max-width: 360px;
    text-align: center;
    
    ${H4} {
        margin: 24px 0 10px;
        position: relative;

        &:after {
            content: "";
            width: 1px;
            display: none;
            position: absolute;
            right: -${BLOCK_SPACING}px;
            top: 0;
            height: 210px;
            background: ${({ theme }) => theme ? theme.marketing.colors.dodgerBlue50 : null};

            ${media.phone} {
                display: block;
            }
        }

        ${media.phone} {
            margin: 50 0 20px;
        }
    }

    ${Body} {
        color:  ${({ theme }) => theme ? theme.marketing.colors.blackPearl80 : null};
    }
`;

export const IconAligner = styled.div<{ $minHeight?: number }>`
    ${media.phone} {
        min-height:  ${({ $minHeight }) => $minHeight ? `${$minHeight}px` : null};
    }
`;