import styled from "styled-components";
import { FREE_ADVANTAGES_PERKS } from "../../utils/freeAdvantagesPerks"
import { Body } from "../Typography";
import { media, theme } from "../../theme/theme";
import { V3IconCheck } from "../Icons";

const FreeAdvantagesWrapper = styled.div`
    width: 100%;
    max-width: 230px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        max-width: 630px;
        flex-direction: row;
    }

    > div {
        display: flex;
        align-items: center;

        > div {
            &:first-child {
                display: inline-flex;
                margin-top: 3px;
                margin-right: 7px;
            }
        }
    }

    ${Body} {
        color: ${({ theme, color }) => color ? color : `${theme.marketing.colors.blackPearl80}`};
        margin: 0;
    }
`;

export const FreeAdvantages = () => {
    return <FreeAdvantagesWrapper>
        {FREE_ADVANTAGES_PERKS.map(adv => <div>
            <div>
                <V3IconCheck />
            </div>
            <Body>{adv}</Body>
        </div>)}
    </FreeAdvantagesWrapper>
}