import styled from "styled-components";
import { MediumText } from "../Typography";

const MarketingCenteredTextIcon = styled(MediumText)`
    display: flex;
    align-items: center;
    color:  ${({ theme }) => theme ? theme.marketing.colors.dodgerBlue100 : null};

    > * {
        &:first-child {
            margin-right: 13px;
        }
    }
`;

type MarketingDashSubtitleProps = {
    subtitle: string
}

export const MarketingDashSubtitle = ({ subtitle }: MarketingDashSubtitleProps) => <MarketingCenteredTextIcon>
    <svg width="24" height="1" viewBox="0 0 24 1" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line y1="0.5" x2="24" y2="0.5" stroke="#2E77FF" />
    </svg>
    <div>
        {subtitle}
    </div>
</MarketingCenteredTextIcon>