import React from "react"
import { MarketingDashSubtitle } from "../MarketingDashSubtitle"
import { Body, H2 } from "../Typography"
import { DesktopImg, MobileImg, ImageContent, TextSVGContainer, TextSVGWrapper, TextWrapper } from "./MarketingTextSVGColumn.styles"
import { CTASize, MarketingCTA } from "../MarketingCTA";

type MarketingTextSVGColumnProps = {
    subtitle?: string,
    title: JSX.Element,
    description: JSX.Element,
    svgPosition?: "left" | "right"
    buttonText?: string,
    desktopImgWidth: number,
    desktopImgHeight: number,
    desktopImage: string,
    mobileImage: string
    mobileMaxWidth?: number,
    snapBr?: number,
    mobileCentered?: boolean
    homepageVariant?: boolean,
}

export const MarketingTextSVGColumn = ({
    subtitle,
    title,
    description,
    svgPosition = "right",
    buttonText,
    desktopImgWidth,
    desktopImgHeight,
    desktopImage,
    mobileImage,
    mobileMaxWidth,
    snapBr,
    mobileCentered = false,
    homepageVariant = false
}: MarketingTextSVGColumnProps) => {

    return <TextSVGWrapper>
        <TextSVGContainer svgPosition={svgPosition} homepageVariant={homepageVariant}>
            <TextWrapper mobileCentered={mobileCentered} homepageVariant={homepageVariant}>
                {subtitle && <MarketingDashSubtitle subtitle={subtitle} />}
                <H2 as="h2">{title}</H2>
                <Body>{description}</Body>
                {buttonText && <a href="mailto:enterprise@authory.com" >
                    <MarketingCTA text={buttonText} size={CTASize.M} />
                </a>}
            </TextWrapper>
            <ImageContent snapBr={snapBr}>
                <DesktopImg
                    desktopImgWidth={desktopImgWidth}
                    desktopImgHeight={desktopImgHeight}
                    snapBr={snapBr}
                >
                    <img src={desktopImage} />
                </DesktopImg>
                <MobileImg maxWidth={mobileMaxWidth}>
                    <img src={mobileImage} />
                </MobileImg>
            </ImageContent>
        </TextSVGContainer>
    </TextSVGWrapper>
}