import React from "react"
import { FTMARKETINGPAGES } from "../../utils/featuresMarketingPage"
import { H2 } from "../Typography"
import { AllBenefitsPanel } from "./AllBenefitsPanel"
import { AllBenefitsContainer, AllBenefitsWrapper, CardContainer, MarketingAllBenefitsBlockVariantTitle } from "./MarketingAllBenefits.styles"
import { FreeAdvantages } from "../ReusableStyledComponents/FreeAdvantages"
import { VerticalSpacing } from "../VerticalSpacing"
import { theme } from "../../theme/theme"

type MarketingAllBenefitsProps = {
    currRoute?: string,
    useBlockVariant?: boolean,
}

export const MarketingAllBenefits = ({ currRoute, useBlockVariant = false }: MarketingAllBenefitsProps) => <AllBenefitsWrapper $useBlockVariant={useBlockVariant}>
    <AllBenefitsContainer >
        {
            !useBlockVariant ? <H2>Check out <strong>all features</strong></H2> : <>
                <VerticalSpacing bottom={50} top={0} breakpoints={[{ screen: theme.breakpoints.tablet, marginTop: -30 }]}>
                    <FreeAdvantages />
                </VerticalSpacing>
                <MarketingAllBenefitsBlockVariantTitle>...or take a look at Authory's features:</MarketingAllBenefitsBlockVariantTitle>
            </>
        }
        <CardContainer>
            {
                FTMARKETINGPAGES.map(({ iconPath, primaryText, secondaryText, route }) => <AllBenefitsPanel
                    key={route}
                    iconPath={iconPath}
                    primaryText={primaryText}
                    secondaryText={secondaryText}
                    route={route}
                    currRoute={currRoute}
                />)
            }
        </CardContainer>
    </AllBenefitsContainer>
</AllBenefitsWrapper>